$color-base: #ff8a00;
body {
    margin: 0;
    background-color: #121b21 !important;
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    color: #fff;

    .site-bg {
        background-size: 100% auto;
        min-height: 100vh;
        background: url("../assets/images/background.png") no-repeat center top;
    }

    .container {
        padding-top: 30px;

        .row {
            margin-bottom: 1rem;
        }
    }

    .hidden {
        display: none;
    }
    a:hover {
        text-decoration: none;
    }
    .card {
        color: black;
    }
    .jumbotron {
        background: transparent;
        border: 1px solid $color-base;
        position: relative;
        padding: 3rem 2rem 0rem 2rem;
    }
    
    .nav-tabs {
        border-bottom: 1px solid #ff8a00;

        .nav-link {
            cursor: pointer;
            &.active {
                background: transparent;
                color: white;
                border-color: #ff8a00 #ff8a00 #ff8a00;
            }
            &:hover {
                border-color: #ff8a00 #ff8a00 #ff8a00;
                color: white;

            }
        }
    } 

    .navbar-brand img {
        width: 200px;
    }
    a {
        color: $color-base;
    }

}

@import "./components/button";
@import "./components/typography";
