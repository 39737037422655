.event {
	.live-logo {
		img {
			width: 100%;
		}
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 70%;
        margin: auto;
        z-index: -1;
        opacity: 0.2;
    }
	.eventType {
		position: absolute;
		left: 10px;
		top: 10px;
		width: 80px;
	}

	.live-status {
		position: absolute;
		right: 10px;
		top: 10px;

		.pulse {
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			cursor: pointer;
			box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
			background: #cc1926;

			&.active {
				background: #13cc18;
				animation: pulse 2s infinite;
			}
		}

	}

	

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0 rgba(42, 204, 44, 0.4);
		}
		70% {
			box-shadow: 0 0 0 10px rgba(42, 204, 44, 0);
		}
		100% {
			box-shadow: 0 0 0 0 rgba(42, 204, 44, 0);
		}
	}
	@keyframes btn-pulse {
		0% {
			box-shadow: 0 0 0 0 rgba(223, 105, 26, 0.4);
		}
		70% {
			box-shadow: 0 0 0 7px rgba(223, 105, 26, 0);
		}
		100% {
			box-shadow: 0 0 0 0 rgba(223, 105, 26, 0);
		}
	}
}
