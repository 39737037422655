.event-card {
    border-radius: 10px;
    border: 1px;
    margin-bottom: 15px;
    transition: all 0.25s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }

    &--active {
        filter: drop-shadow(0px 0px 5px #ff8a00);
    }
    &__body {
        background: #003e60;
        display: flex;
        border-radius: 5px 5px 0 0;
        flex-direction: column;
        padding: 5px;
        cursor: pointer;

        .eventType {
            height: 20px;
            width: auto;
            align-self: flex-end;
        }

        .status {
            align-self: flex-start;
        }

        svg {
            width: auto;
            height: 80px;
            margin: 10px 0;
            filter: drop-shadow(5px 5px 5px #000);
        }
    }
    &__footer {
        background: #ff8a00;
        text-align: center;
        display: flex;
        align-content: center;
        flex-direction: column;
        padding: 10px 0;
        border-radius: 0 0 5px 5px;
        cursor: pointer;
        p {
            margin: 0;
            font-size: 0.65rem;
            line-height: 12px;
            font-weight: 600;
        }
    }
}
